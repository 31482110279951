

































import { Component, Vue } from 'nuxt-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { Report } from 'powerbi-client'
import FilterElement from './FilterElement.vue'
import ReportModule from '~/store/ReportModule'
import PowerBiModule from '~/store/PowerBiModule'

@Component({
  components: { FilterElement },
})
export default class FilterPanel extends Vue {
  public showPanel: boolean = true

  get getFilters() {
    return this.reportStore.filters
  }

  /* Data */
  reportStore: ReportModule = getModule(ReportModule)
  powerbiStore: PowerBiModule = getModule(PowerBiModule)

  async mounted() {
    await this.reportStore.getFilters()
  }

  onSetShow() {
    if (this.showPanel) {
      this.showPanel = false
    } else {
      this.showPanel = true
    }
  }

  onSetFilter() {
    const report = this.powerbiStore.getPBIReport as Report
    const fiterList = [] as any[]

    const groupedByFilters = this.reportStore.activeFilters.filter((p) => p.values.length > 0)

    groupedByFilters.forEach((activefilter) => {
      const values = activefilter.values.map(({ value }) => value)

      const filter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        filterType: 1,
        target: {
          table: activefilter.table,
          column: activefilter.column,
        },
        operator: activefilter.operator,
        values: values,
      }
      fiterList.push(filter)
    })
    report.setFilters(fiterList as any[])
  }
}
