export class ApiUrl {
  /**
   * ApiUrl() gives the endpoint for the web api
   */
  public static webapiurl() {
    switch (window.location.host) {
      case 'mvo.wecr.wur.nl':
        return 'https://mvo.wecr.wur.nl/WebApi'
      case 'acc.mvo.wecr.wur.nl':
        return 'https://acc.mvo.wecr.wur.nl/WebApi'
      case 'tst.mvo.wecr.wur.nl':
        return 'https://tst.mvo.wecr.wur.nl/WebApi'
      default:
        return 'http://localhost:50256'
    }
  }
}
