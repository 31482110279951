














import { Component, Vue } from 'nuxt-property-decorator'
import DefaultTemplate from '@template/components/defaultTemplate.vue'
import SmallMenu from '@template/components/SmallMenu.vue'
import { IMenuItem, MenuItem } from '@template/classes/menu'
import { getModule } from 'vuex-module-decorators'
import DefaultMenuUser from '@template/components/defaultMenuUser.vue'
import { Report } from 'powerbi-client'
import LoginModule from '~/store/LoginModule'
import ReportModule from '~/store/ReportModule'
import FilterPanel from '~/components/FilterPanel.vue'
import PowerBiModule from '~/store/PowerBiModule'

@Component({
  components: { DefaultMenuUser, SmallMenu, DefaultTemplate, FilterPanel },
})
export default class Default extends Vue {
  /* Props */
  public report: Report = {} as Report
  /* Watch */

  /* Data */
  loginStore: LoginModule = getModule(LoginModule)
  reportStore: ReportModule = getModule(ReportModule)
  powerbiStore: PowerBiModule = getModule(PowerBiModule)

  menuItems: IMenuItem[] = []

  /* Enums */

  /* Lifecycle */
  async created() {
    await this.reportStore.getReportsAndPages()

    const reports = this.reportStore.reports

    reports.forEach((report) => {
      this.menuItems.push(
        new MenuItem({
          title: report.name,
          subTitle: report.description,
          disabled: false,
          icon: 'mdi-google-circles-communities',
          location: {
            name: 'index-page',
            params: {
              page: 'Summary',
            },
          },
        })
      )
      report.pages.forEach((page) => {
        this.menuItems.push(
          new MenuItem({
            title: page.name,
            icon: page.icon,
            color: 'success',
            location: {
              name: 'index-page',
              params: {
                page: page.name,
              },
            },
            // onClick: () => this.SetPageOnReport(page.name),
          })
        )
      })
    })
  }

  /* Computed */

  /* Methods */
  public async SetPageOnReport(pageName: string) {
    this.report = this.powerbiStore.getPBIReport
    const pages = await this.report.getPages()
    const selectedPage = pages.filter((p) => p.displayName === pageName)
    await selectedPage[0].setActive()
  }

  get isLoggedIn(): boolean {
    return !!this.loginStore.token.length
  }
}
