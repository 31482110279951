import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { getModule } from 'vuex-module-decorators'
import LoginModule from '~/store/LoginModule'

const loginStore: LoginModule = getModule(LoginModule)

const auth: Middleware = (context: Context) => {
  if (context.route.name?.startsWith('auth')) {
    return
  }

  const tokens = localStorage.getItem('token')
  const isLoggedIn = !!tokens?.length

  if (context.route.params) {
    const token = context.route.query.Token
    if (token !== undefined) {
      loginStore.removeToken()
      loginStore.setToken(token.toString())
      return
    }

    if (isLoggedIn) {
      return
    }
  }

  return context.redirect({
    name: 'auth-login',
  })
}

export default auth
